import React, {useEffect, useState} from 'react';
import {Communications} from "./helpers";

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import packageData from '../package.json';
import MainRouter from './navigation/navigator';
import GlobalLoaderComponent from './components/globalLoader/globalLoaderComponent';


const APP_VERSION = packageData.version;

const App = () => {
    const [isPageLoading, setIsPageLoading] = useState(false);

    useEffect(() => {
        const pageLoadingSub = Communications.PageLoadingStateSubject.subscribe(isPageLoading => {
            setIsPageLoading(isPageLoading);
        });
        return () => {
            pageLoadingSub.unsubscribe();
        }
    }, []);

    return (
        <div className="App">
            {isPageLoading && <GlobalLoaderComponent/>}
            <MainRouter/>
            <div className={"help"}>
                <div>{<a href={"https://www.nbcuniversal.com/privacy"} target="_blank" rel="noopener noreferrer"
                         style={{color: "#aaaaaa"}}>Privacy Policy</a>}</div>
                <div>{<a href={"https://www.nbcuniversal.com/privacy/notrtoo"} target="_blank" rel="noopener noreferrer"
                         style={{color: "#aaaaaa"}}>Your Privacy Choices</a>}</div>
                <div>{<a href={"https://www.nbcuniversal.com/terms"} target="_blank" rel="noopener noreferrer"
                         style={{color: "#aaaaaa"}}>Terms of Use</a>}</div>
                <div>{<a href={"https://www.nbcuniversal.com/privacy/california-consumer-privacy-act"} target="_blank"
                         rel="noopener noreferrer"
                         style={{color: "#aaaaaa"}}>CA notice</a>}</div>
                <div style={{color: "#aaaaaa"}}>v{APP_VERSION}</div>
            </div>
            <div className={'app_version_wrapper'}></div>
        </div>
    );
};

export default App;
