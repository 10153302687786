import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import NotFoundComponent from '../components/NotFoundComponent';
import AppLayout from '../app/layout/app-layout';
import HomeComponent from '../app/pages/homePage/home.component';
import TvProviderListComponent from '../app/pages/tvProviderListPage/tvProviderList.component';
import ReturnFromTVProviderComponent from '../app/pages/returnFromTVProvider/returnFromTVProvider.component';
import SuccessComponent from '../app/pages/successPage/success.component';
import ErrorPageComponent from '../app/pages/errorPage/errorPage.component';
import FaqComponent from "../app/pages/faqPage/Faq.component";

// @ts-ignore
const AppLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <AppLayout>
                    <Component {...matchProps} />
                </AppLayout>
            )}
        />
    );
}

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Navigator />
        </BrowserRouter>
    );
};
const Navigator = (props: any) => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={'/activate'} />
            </Route>

            <AppLayoutRoute path="/activate" component={HomeComponent} />
            <AppLayoutRoute path="/tv-providers/:tvCode" component={TvProviderListComponent} />
            <AppLayoutRoute path="/return-from-tv-provider/:mvpd/:tvCode" component={ReturnFromTVProviderComponent} />
            <AppLayoutRoute path="/success" component={SuccessComponent} />
            <AppLayoutRoute path="/error-page" component={ErrorPageComponent} />
            <AppLayoutRoute path="/help-center" component={FaqComponent} />
            <Route path="/not-found">
                <NotFoundComponent />
            </Route>
            <Route path="*">
                <Redirect to={'/not-found'} />
            </Route>
        </Switch>
    );
};

export default MainRouter;
