import { SAMPLE_ACTION } from '../actions/others.action';

export interface OtherParams {
    sampleData?: any;
}

const initialData: OtherParams = {
    sampleData: null,
};
const othersReducer = (state = initialData, action: any): OtherParams => {
    switch (action.type) {
        case SAMPLE_ACTION:
            state = { ...state, sampleData: action.payload };
            return state;
        default:
            return state;
    }
};

export default othersReducer;
