import React from 'react'
import "./errorPage.component.scss"
import {errorLogo} from "../../../constants/ImageConfig"
import {useHistory} from "react-router-dom"

const ErrorPageComponent = () => {
    const history = useHistory()

    const handleTryAgain = () => {
        history.push('/activate')
    }

    return (
       <div className="main-wrapper">
            <div className="aligns-wrapper">
                <div className="logo-wrapper">
                    <img src={errorLogo} alt="errorLogo" />
                </div>
               <div className="description">
                    <div>Your activation code has expired or is invalid.</div>
				   	<div>Please generate a new code from your device.</div>
               </div>
               <button onClick={handleTryAgain}>TRY AGAIN</button>
            </div>
        </div>
    )
}

export default ErrorPageComponent
