import React from 'react'
import "./success.component.scss"

const SuccessComponent = () => {
    return (
        <div className="main-wrapper">
            <div className="align-wrapper">
                <div className="description-wrapper">
                    <div className="congratulations">Congratulations</div>
                    <div className="linked">You're all set</div>
                </div>
                <div className="sub-description">Enjoy the Paris Olympics 2024</div>
            </div>
        </div>
    )
}

export default SuccessComponent
