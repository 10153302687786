import React from 'react'
import MenuBarComponent from './MenuBarComponent'

const HeaderComponent = () => {
    return (
        <div>
           <MenuBarComponent /> 
        </div>
    )
}

export default HeaderComponent
