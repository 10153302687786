import React from 'react'
import "./MenuBarComponent.scss"
import {ParisOlympicsLogoWhite} from '../../constants/ImageConfig'


const MenuBarComponent = () => {

    return (
        <div className="menu">
            <img src={ParisOlympicsLogoWhite} alt="beijingLogo" />
        </div>
    )
}

export default MenuBarComponent
