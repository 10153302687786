import { combineReducers } from 'redux';
import othersReducer, { OtherParams } from './others.reducer';

export interface StateParams {
    other: OtherParams;
}

const rootReducer = combineReducers({
    other: othersReducer
});

export default rootReducer;
