import React, { PropsWithChildren } from 'react';
import './app-layout.scss';
import HeaderComponent from '../../components/theme/HeaderComponent';

export interface AppLayoutProps {
}

const AppLayout = (props: PropsWithChildren<AppLayoutProps>) => {
    return (
        <div className={ 'app-layout' }>
            <HeaderComponent/>
            <div className="page-container">{props.children}</div>
        </div>
    );
};

export default AppLayout;
