import React, { useEffect, useState } from 'react'
import "./tvProviderList.component.scss"
import { ApiService } from "../../../helpers";
import ENV from "../../../constants/ENV";
import GlobalLoaderComponent from '../../../components/globalLoader/globalLoaderComponent';

type TvProviderListComponentProps = {
    match: {
        params: {
            tvCode: string;
        }
    }
}

const TvProviderListComponent = (props: TvProviderListComponentProps) => {
    const [tvProviderList, setTvProviderList] = useState<any>([]);


    useEffect(() => {
        ApiService.get(`${ENV.AUTH_URL}/${props.match.params.tvCode}/mvpds`)
            .then((response) => {
                setTvProviderList(response.data.mvpds)
            })
            .catch((error) => console.log(error))
    }, [props])

    if(tvProviderList.length > 0) {
        return (
            <div className="tvProviderList-wrapper">
                <div className="description">Select your TV provider</div>
                {tvProviderList.map((tvProvider: { url: string, title: string }, tvProviderIndex: any) => {
                    return (
                        <a href={tvProvider.url} key={tvProviderIndex}>
                            <div className="title-wrapper">
                                <div className="title">{tvProvider.title}</div>
                            </div>
                        </a>
                    )
                })}
            </div>
        )
    }else {
        return(<GlobalLoaderComponent/>)
    }
}

export default TvProviderListComponent
