import React from 'react';
import './globalLoaderComponent.scss'
import {ImageConfig} from "../../constants";
import {ParisOlympicsLogoWhite} from "../../constants/ImageConfig";

const GlobalLoaderComponent = (props: any) => {
    return (
        <div className="loadingWrapper">
            <div className="loadingContainer">
                <div className="brandingLogo">
                    <img src={ParisOlympicsLogoWhite} alt={"branding Logo"} style={{width:"300px"}}/>
                </div>
                <div className="loadingSpinner">
                    <div className="spinner-holder">
                        <img src={ ImageConfig.SpinnerWhite } alt={ 'Loading icon' }/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GlobalLoaderComponent;

