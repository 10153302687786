import { BehaviorSubject, Subject } from 'rxjs';
import { TsConfirmationConfig } from '../constants/CommonTypes';

const ReloadStateSubject: Subject<boolean> = new Subject<boolean>();
const PageLoadingStateSubject: Subject<boolean> = new Subject<boolean>();

const ConfirmStateSubject: Subject<{
    config: TsConfirmationConfig;
    promise: { resolve: any; reject: any };
}> = new Subject<{ config: TsConfirmationConfig; promise: { resolve: any; reject: any } }>();
const DialogStateSubject: Subject<{ component: any; promise: { resolve: any; reject: any } }> = new Subject<{
    component: any;
    promise: { resolve: any; reject: any };
}>();


const accessDoctorLinkSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
const Communications = {
    ReloadStateSubject,
    PageLoadingStateSubject,
    ConfirmStateSubject,
    accessDoctorLinkSubject,
    DialogStateSubject,
};

export default Communications;
