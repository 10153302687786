import React from "react";
import "./Faq.component.scss";
import FAQDATA from "../../../assets/data/faqData.json";

const FaqComponent = () => {
    return (
        <div className={"faq-wrapper"}>
            <div className={"title"}>Help Center</div>
            <div className="accordion-menu">
                <div className="tabs">
                    {FAQDATA.map((item: any, index) => {
                        return (
                            <>
                                <hr/>
                                <div className="tab" key={index + "-" + item.ques}>
                                    <input type="radio" id={item.id} name="rd"/>
                                    <label className="tab-label" htmlFor={item.id}><span
                                        className={"ques"}><strong>{item.ques}</strong></span></label>
                                    <div className="tab-content">
                                        {item.answer}
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    <hr/>
                </div>
            </div>
            <div className={"footer"}>Still having trouble? Email customer support at support@nbcsports.com</div>
            {/*<div className={"action"}>*/}
            {/*    <button>Chat</button>*/}
            {/*</div>*/}
        </div>
    )
}

export default FaqComponent;
