import React, { useEffect, useState } from 'react'
import "./returnFromTVProvider.component.scss"
import { ApiService } from "../../../helpers";
import ENV from "../../../constants/ENV";
import { useHistory } from "react-router-dom";
import GlobalLoaderComponent from '../../../components/globalLoader/globalLoaderComponent';

type ReturnFromTVProviderComponentProps = {
    match: {
        params: {
            tvCode: string;
            mvpd: string;
        }
    }
}

const ReturnFromTVProviderComponent = (props: ReturnFromTVProviderComponentProps) => {
    const history = useHistory()
    const [authenticationFailure, setAuthenticaionFailure] = useState<boolean>(false)

    useEffect(() => {
        const timer = setInterval(() => {
            ApiService.get(`${ENV.AUTH_URL}/${props.match.params.tvCode}/check-authenticated`)
                .then((response) => {
                    setAuthenticaionFailure(response.data.authenticated)
                    if (response.data.authenticated) {
                        history.push("/success")
                    }
                })
                .catch((error) => console.log(error))
        }, 3000);
        return () => clearInterval(timer);
    }, [props, history])

    useEffect(() => {
        const redirectToErrorPage = setInterval(() => {
            if (!authenticationFailure) {
                history.push("/error-page")
            }
        }, 30000)
        return () => clearInterval(redirectToErrorPage);
    }, [history])


    return (
        // <div className="main-wrapper">
        //     <div className="align-wrapper">
        //         <div className="description-wrapper">
        //             <div className="congratulations">Please wait...</div>
        //             <div className="linked">Redirecting to {props.match.params.mvpd.toUpperCase()} to Sign In</div>
        //         </div>
        //     </div>
        // </div>
        <GlobalLoaderComponent />
    )
}

export default ReturnFromTVProviderComponent
