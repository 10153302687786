import React, { useEffect, useState } from 'react'
import "./home.component.scss"
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import {ApiService, CommonService} from "../../../helpers";
import ENV from "../../../constants/ENV";

const HomeComponent = () => {
    const params = CommonService.parseQueryString(useLocation().search)
    const history = useHistory()
    const [tvCode, setTvCode] = useState(params.code || '');
	const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
	const [tvCodeExpiredError, setTvCodeExpiredError] = useState<string>("");
	const [isValid, setTvCodeValid] = useState<boolean>(false);

	useEffect(() => {
		if(isValid) {
			history.push(`/tv-providers/${tvCode}`);
		}
	}, [isValid])

	useEffect(() => {
		if(!formSubmitted) {
			return
		}
		ApiService.get(`${ENV.AUTH_URL}/registration/${tvCode}`)
			.then((response) => {
				console.log(response)
				setTvCodeExpiredError("")
				setTvCodeValid(true)
			})
			.catch((error) => {
				console.log(error)
				setTvCodeExpiredError("Your activation code has expired or is invalid. Please generate a new code from your device.")
				setTvCodeValid(false)
			})
	}, [tvCode, formSubmitted])

    const handleChange = (e: any) => {
        setTvCode(e.target.value.trim().replace(/\s/g, "").toUpperCase())
    }

    const onSubmit = (e:any) => {
        e.preventDefault();
        setFormSubmitted(true)
    }

    return (
        <div className="input-wrapper">
            <div className="home-screen">
                <div className="main_div">
                    <div className="description">Enter the code displayed on your TV</div>
                    <form className="input-group" noValidate onSubmit={onSubmit}>
                        <label>TV Code</label>
                        <input type="text" value={tvCode} required onChange={handleChange}/>
						{formSubmitted && !isValid && <div className={"error-message"}>{tvCodeExpiredError}</div> }
                        <div className="actions_div">
                            <button className="continue" type={"submit"}>CONTINUE</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default HomeComponent
